import React, { lazy } from 'react';
// import { Redirect } from 'react-router-dom';
import { isMobileOnly } from 'mobile-device-detect';

const Feed = lazy(() => import('../src/pages/Feed'));
const Detail = lazy(() => import('../src/pages/WpPostDetail'));

const Events = lazy(() => import('../src/pages/Events'));
const EventDetails = lazy(() => import('../src/pages/EventDetail'));
const Profile = lazy(() => import('../src/pages/Profile'));
const Articles = lazy(() => import('../src/pages/Articles'));

const Explorer = lazy(() => import('../src/pages/Explorer'));
const TopRated = lazy(() => import('../src/pages/TopRated'));

const SavedItems = lazy(() => import('../src/pages/SavedItems'));
const FriendsList = lazy(() => import('../src/pages/FriendsList'));

const ShopResults = lazy(() => import('../src/pages/ShopResults'));
const ShopAll = lazy(() => import('../src/pages/ShopAll'));
const ShopLocal = lazy(() => import('../src/pages/ShopLocal'));

const CigarDetails = lazy(() => import('../src/pages/CigarDetails'));
const VenueListings = lazy(() => import('../src/pages/VenueMapListings'));
const VenueDetails = lazy(() => import('../src/pages/VenueDetails'));
const ProductDetails = lazy(() => import('../src/pages/ProductDetails'));

const RecentCigars = lazy(() => import('../src/pages/RecentCigars'));
const FavoriteCigars = lazy(() => import('../src/pages/FavoriteCigars'));

const Humidor = lazy(() => import('../src/pages/Humidor'));

const ClaimBusiness = lazy(() => import('../src/pages/ClaimBusiness'));

const UserSettings = lazy(() => import('../src/pages/Settings'));
const NotificationSettings = lazy(() => import('../src/pages/Settings/notifications'));
const BillingSettings = lazy(() => import('../src/pages/Settings/billing'));
const UserNametag = lazy(() => import('../src/pages/ProfileNametag'));

const BrandDetails = lazy(() => import('../src/pages/BrandDetails'));
const Groups = lazy(() => import('../src/pages/Groups'));
const GroupListings = lazy(() => import('../src/pages/GroupListings'));
const GroupProfile = lazy(() => import('../src/pages/GroupProfile'));
const GroupInfo = lazy(() => import('../src/pages/GroupInfo'));
const Comments = lazy(() => import('../src/pages/Comments'));

const Cart = lazy(() => import('../src/pages/Cart'));
const Checkout = lazy(() => import('../src/pages/Checkout'));

const Orders = lazy(() => import('../src/pages/Orders'));
const OrderDetails = lazy(() => import('../src/pages/OrderDetails'));
const RecentVenues = lazy(() => import('../src/pages/RecentVenues'));
const PendingScan = lazy(() => import('../src/pages/PendingScan'));
const PWAInstall = lazy(() => import('../src/pages/Install'));

const Alerts = lazy(() => import('../src/pages/Alerts'));

const SearchView = lazy(() => import('../src/pages/Search'));

// INFO These routes get created for tabs on desktop so don't need to be defined at the global level
const mobileOnlyRoutes = isMobileOnly ? [
  {
    path: '/users/:userId/humidors',
    exact: true,
    component: Humidor,
  },
  {
    path: '/profile/humidors',
    private: true,
    exact: true,
    component: Humidor,
  },
  {
    path: '/users/:userId/favorites/cigars',
    private: true,
    component: FavoriteCigars,
  },
  {
    path: '/profile/favorites/cigars',
    private: true,
    component: FavoriteCigars,
  },
  {
    path: '/users/:userId/trylist',
    private: true,
    component: SavedItems,
  },
  {
    path: '/users/:userId/friends',
    private: true,
    component: FriendsList,
  },
  {
    path: '/profile/friends',
    private: true,
    component: FriendsList,
  },
  {
    path: '/scans/pending/:hashId',
    private: true,
    exact: true,
    component: PendingScan,
  },
  {
    path: '/groups/:groupId/info',
    component: GroupInfo,
  },
  {
    path: '/alerts',
    private: true,
    component: Alerts,
  },
] : [];

export default [
  {
    path: '/',
    exact: true,
    component: Feed,
  },
  {
    path: '/profile/orders',
    private: true,
    component: Orders,
  },
  {
    path: '/orders/:orderId',
    component: OrderDetails,
  },
  {
    path: '/profile',
    exact: isMobileOnly,
    private: true,
    component: Profile,
  },
  {
    path: '/users/:userId',
    exact: isMobileOnly,
    private: true, // INFO See BXPR-562 - for now, this needs to be private
    component: Profile,
  },
  {
    path: '/settings/notifications',
    private: true,
    component: NotificationSettings,
  },
  {
    path: '/billing',
    private: true,
    component: BillingSettings,
  },
  {
    path: '/settings',
    private: true,
    component: UserSettings,
  },
  {
    path: '/users/:userId/nametag',
    private: true,
    component: UserNametag,
  },
  {
    path: '/explorer',
    component: Explorer,
  },
  {
    path: '/top-rated',
    component: TopRated,
  },
  {
    path: '/saved',
    private: true,
    exact: true,
    component: SavedItems,
  },
  {
    path: '/users/:userId/recents/cigars',
    component: RecentCigars,
  },
  {
    path: '/recents/cigars',
    private: true,
    component: RecentCigars,
  },
  {
    path: '/users/:userId/recents/venues',
    component: RecentVenues,
  },
  {
    path: '/recents/venues',
    private: true,
    component: RecentVenues,
  },
  // INFO We now have a separate app for this. I don't see any need for this page, so phase it out
  // {
  //   path: '/lounge',
  //   private: true,
  //   exact: true,
  //   component: VirtualLounge,
  // },
  {
    // FIXME Can we redirect this to /offers?
    path: '/deals',
    component: ShopResults,
  },
  {
    path: '/offers',
    component: ShopResults,
  },
  {
    path: '/shop/products',
    component: ShopResults,
  },
  {
    path: '/shop/all',
    component: ShopAll,
  },
  {
    path: '/shop/local',
    component: ShopLocal,
  },
  {
    path: '/products/reviews/:reviewId/comments',
    component: Comments,
    private: true,
  },
  {
    path: '/products/:productId',
    component: ProductDetails,
  },
  {
    path: '/sessions/:sessionId/comments',
    component: Comments,
    private: true,
  },
  {
    path: '/brands/claim',
    component: ClaimBusiness,
  },
  {
    path: '/brands/:brandId',
    component: BrandDetails,
  },
  {
    path: '/groups/posts/:groupPostId/comments',
    component: Comments,
    private: true,
  },
  {
    path: '/groups/claim',
    component: ClaimBusiness,
  },
  {
    path: '/groups/browse',
    component: GroupListings,
  },
  {
    path: '/groups/:groupId',
    component: GroupProfile,
  },
  {
    path: '/groups/',
    component: Groups,
    private: true,
  },
  {
    path: '/posts/:postId/comments',
    component: Comments,
    private: true,
  },
  {
    path: '/blogs/articles/:blogArticlePostId/comments',
    component: Comments,
    private: true,
  },
  {
    path: '/podcasts/episodes/:podcastPostId/comments',
    component: Comments,
    private: true,
  },
  {
    path: '/businesses/claim',
    component: ClaimBusiness,
  },
  // {
  //   path: '/mailbox',
  //   component: Mailbox,
  // },
  // {
  //   path: '/messenger',
  //   component: Messenger,
  // },
  {
    path: '/events/:eventId/comments',
    component: Comments,
    private: true,
  },
  {
    path: '/events/:eventId',
    component: EventDetails,
  },
  {
    path: '/events',
    component: Events,
  },
  {
    path: '/articles/:articleId/comments',
    component: Comments,
    private: true,
  },
  {
    path: '/articles/:postId',
    component: Detail,
  },
  {
    path: '/articles',
    exact: true,
    component: Articles,
  },
  {
    path: '/cigars/:cigarId',
    component: CigarDetails,
  },
  {
    path: '/venues',
    exact: true,
    component: VenueListings,
  },
  {
    path: '/venues/claim',
    private: true,
    // component: <Redirect to="/businesses/claim" />,
    component: ClaimBusiness,
  },
  {
    path: '/venues/:venueId',
    component: VenueDetails,
  },
  {
    path: '/checkins/:checkinId/comments',
    component: Comments,
    private: true,
  },
  {
    path: '/cart',
    component: Cart,
  },
  {
    path: '/checkout',
    component: Checkout,
  },
  {
    path: '/plans/checkout',
    component: Checkout,
  },
  {
    path: '/install',
    component: PWAInstall,
  },
  {
    path: '/search',
    component: SearchView,
  },
  ...mobileOnlyRoutes,
];
